<template>
  <TTView>
    <VRow>
      <VCol>
        <RoutesTableContainer />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import RoutesTableContainer from '../../components/sysadmin/RoutesTableContainer.vue';

export default {
  name: 'RouteList',

  components: {
    RoutesTableContainer,
  },

  inject: ['Names'],
};
</script>
