<template>
  <RoutesTable
    :routes="routes"
    :loading="loading"
  >
    <template #topActions>
      <VBtn
        color="primary"
        :to="{ name : Names.R_SYSADMIN_ROUTES_CREATE }"
      >
        <VIcon left>
          fal fa-plus
        </VIcon>

        Новый маршрут
      </VBtn>

      <VDialog
        v-model="confirmDialog"
        max-width="500px"
        @click:outside="handleCancel"
      >
        <VCard>
          <VCardTitle class="headline">
            Удалить маршрут
          </VCardTitle>

          <VCardText>
            Маршрут <span class="red--text">{{ route.id }}</span> будет удален! <br>
            Действительно хотите удалить приглашение?
          </VCardText>

          <VCardActions>
            <VSpacer />

            <VBtn
              text
              @click="handleCancel"
            >
              Нет
            </VBtn>

            <VBtn
              color="red darken-1"
              text
              @click="handleDelete"
            >
              Да
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    </template>

    <template #rowActions="{ item }">
      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="blue"
            class="mx-1"
            :to="{ name : Names.R_SYSADMIN_ROUTES_VIEW, params : { routeId : item.id } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-eye
            </VIcon>
          </VBtn>
        </template>

        <span>Просмотр</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="orange"
            class="mx-1"
            :to="{ name : Names.R_SYSADMIN_ROUTES_EDIT, params : { routeId : item.id } }"
            v-bind="attrs"
            v-on="on"
          >
            <VIcon small>
              fal fa-edit
            </VIcon>
          </VBtn>
        </template>

        <span>Редактирование</span>
      </VTooltip>

      <VTooltip left>
        <template #activator="{ on, attrs }">
          <VBtn
            icon
            small
            color="red"
            class="mx-1"
            v-bind="attrs"
            v-on="on"
            @click.stop="deleteRoute(item)"
          >
            <VIcon small>
              fal fa-trash
            </VIcon>
          </VBtn>
        </template>

        <span>Удаление</span>
      </VTooltip>
    </template>
  </RoutesTable>
</template>

<script>
import { DEFAULT_ROUTE } from './common';
import RoutesTable from './RoutesTable.vue';

export default {
  name: 'RoutesTableContainer',

  components: {
    RoutesTable,
  },

  inject: ['Names'],

  data() {
    return {
      confirmDialog: false,
      routes: [],
      route: { ...DEFAULT_ROUTE },
      loading: false,
      pagination: {
        offset: 0,
        limit: 10,
      },
      total: 0,
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    fetch() {
      this.loading = true;

      this.$di.api.Sysadmin
        .indexRoute()
        .then((response) => {
          this.routes = response;
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },

    deleteRoute(item) {
      this.confirmDialog = true;
      this.route = { ...item };
    },

    handleDelete() {
      this.loading = true;

      const { id } = this.route;

      this.$di.api.Sysadmin
        .deleteRoute(id)
        .then((response) => {
          this.routes = response;
          this.resetRouteEdit();
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
          this.fetch();
        });
    },

    handleCancel() {
      this.resetRouteEdit();
    },

    resetRouteEdit() {
      this.confirmDialog = false;
      this.formDialog = false;
      this.route = { ...DEFAULT_ROUTE };
    },
  },
};
</script>
